import _ from 'lodash'
import {withActions} from 'carmi-host-extensions'

export const name = 'NavigationBaseAspect'

export const defaultModel = {
    dyingPrimaryPageInfo: null,
    isduringTransition: false,
    nextNavigationInfoForSM: null,
    prevPages: [],
    renderedPages: [],
    isTpaInnerRouteNavigation: false,
    navigationCallbacks: []
}


const isChangingRoute = (currentNavInfos, nextNavInfos) => {
    const nextPrimary = nextNavInfos.primaryPage
    const currentPrimary = currentNavInfos.primaryPage
    return nextPrimary.pageId !== currentPrimary.pageId ||
        nextPrimary.innerRoute !== currentPrimary.innerRoute ||
        nextPrimary.tpaInnerRoute !== currentPrimary.tpaInnerRoute
}

const isTpaInnerRouteNavigation = (currentNavInfos, nextNavInfos) => {
    const nextPrimary = nextNavInfos.primaryPage
    const currentPrimary = currentNavInfos.primaryPage

    return nextPrimary.pageId === currentPrimary.pageId && nextPrimary.tpaInnerRoute !== currentPrimary.tpaInnerRoute
}

const shouldReportBI = (currentNavInfos, navInfo) => {
    if (navInfo.isPopup) {
        return false
    }

    return isChangingRoute(currentNavInfos, {primaryPage: navInfo})
}

export const functionLibrary = {
    startNavigationProcess: withActions((actions, currentNavInfos, setToHostInstance, isAllowedBySM, getPrevState, reportPageNavigation, reportPageNavigationInteractionStarted, navInfo) => {
        const isDynamicPageRouting = !navInfo.pageId && navInfo.routerDefinition

        if (navInfo.isPopup || isAllowedBySM(navInfo.pageId) || isDynamicPageRouting) {
            actions.setNextNavigationForSM(null)

            const prevState = getPrevState()
            const currentPrevPages = prevState.prevPages
            if (!_.includes(currentPrevPages, prevState.primaryPageId)) {
                actions.savePageInPrevPages(currentPrevPages.length, 0, prevState.primaryPageId)
            }

            if (shouldReportBI(currentNavInfos, navInfo)) {
                reportPageNavigation(navInfo.pageId)
            }

            if (currentPrevPages[0] !== 'masterPage') {
                actions.savePageInPrevPages(0, 0, 'masterPage')
            }

            let nextNavInfos = navInfo.isPopup ?
                _.assign({}, currentNavInfos, {popupPage: navInfo}) :
                {primaryPage: navInfo}

            if (navInfo.isKeepingRoots) {
                nextNavInfos = _.assign({}, nextNavInfos, {popupPage: navInfo})
            }

            if (navInfo.pageId) {
                reportPageNavigationInteractionStarted()
            }

            setToHostInstance(_.cloneDeep(nextNavInfos))
        } else {
            actions.setNextNavigationForSM(navInfo)
        }
    }),

    actualNavigate: withActions((actions, currentNavInfos, nextNavInfos) => {
        actions.setNextNavigationForSM(null)
        actions.setRootNavigationInfos(nextNavInfos)

        if (isChangingRoute(currentNavInfos, nextNavInfos)) {
            actions.setDyingPrimaryPageInfo(currentNavInfos.primaryPage)
            actions.setIsDuringTransition(true)
        }

        if (isTpaInnerRouteNavigation(currentNavInfos, nextNavInfos)) {
            actions.setIsTpaInnerRouteNavigation(true)
        }
    }),

    endTransition: withActions((actions, navigationCallbacks) => {
        actions.setDyingPrimaryPageInfo(null)
        actions.setIsDuringTransition(false)
        actions.setIsTpaInnerRouteNavigation(false)
        _.forEach(navigationCallbacks, cb => cb())
        actions.setNavigationCallbacks(0, navigationCallbacks.length)
    }),

    addPageToRenderedPages: withActions((actions, dataRequirementsState, pageId, getCurrentRenderedPages) => {
        if (!dataRequirementsState) {
            return
        }
        const currentRenderedPages = getCurrentRenderedPages()
        if (!_.includes(currentRenderedPages, pageId)) {
            actions.savePageInRenderedPages(currentRenderedPages.length, 0, pageId)
        }
    }),

    registerNavigationCallbacks: withActions(({setNavigationCallbacks}, navigationCallbacks, cb) => {
        setNavigationCallbacks(navigationCallbacks.length, 0, cb)
    })
}
