import _ from 'lodash'
import {mapLanguageCodeToName} from 'santa-multilingual/dist/languages'

const defaultModel = {}
const name = 'multilingual'
const functionLibrary = {
    mapLanguageCodeToName,
    handleLanguageChange: (languageCode, currentNavigationInfo, navigateToPageFn) => {
        const newNavigationInfo = _.cloneDeep(currentNavigationInfo)
        _.set(newNavigationInfo, 'queryParams.lang', languageCode)
        _.unset(newNavigationInfo, 'anchorData')
        navigateToPageFn(newNavigationInfo)
    }
}

export {name, functionLibrary, defaultModel}
